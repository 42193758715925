
<i18n locale="th" lang="yaml" >
page.title : "ศูนย์บริการ"
page.description : "แสดงและค้นหาศูนย์บริการของ Tiger Drone"
button.create_company : "เพิ่มศูนย์บริการใหม่"
</i18n>

<template>
	<div class="page page-padding">
		<my-page-header
			:title="$t('page.title')"
			:description="$t('page.description')"/>
		<a-button
			v-if="canCreateCompany"
			class="btn-page-action"
			type="primary"
			icon="plus"
			@click="clickCreateCompany">
			{{$t('button.create_company')}}
		</a-button>
		<CompanyList
			v-if="companies.length"
			:companies="companies"/>
	</div>
</template>

<script>
import axios from "axios"
import CompanyList from "@components/company/CompanyList.vue"


export default {
	components : {
		 CompanyList
	} ,
	page() {
		return {
			title: this.$t('page.title'),
		}
	},
	data() {
		return {
			companies : [] ,
			loading: false,
		}
	} ,
	computed: {
		canCreateCompany() {
			return this.$authorize('create','company')
		} ,
	} ,
	mounted() {
		this.fetchData();
	} ,
	methods : {
		clickCreateCompany() {
			this.$router.push({name : "company/create"});
		} ,
		fetchData() {
			this.loading = true
			axios.get("/api/companies/").then((response) => {
				this.companies = response.data.data.companies
			}).catch((error) => {
				this.fetchError(error)
			}).finally(() => {
				this.loading = false
			})
		}
	}
}
</script>
